import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import {VIDEO_DISPLAYER_LIST} from "../../client-data/clientOptions";
import VideoDisplayerComponent from "./VideoDisplayerComponent";


export default class VideosDisplayerComponent extends Component {
    constructor(props) {
        super(props);
        this.onClickThumbnail = this.onClickThumbnail.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    onClickThumbnail = (videoDataObj) => {
        // window.alert("[VideosDisplayerComponent], Play Video: " + videoDataObj.videoId);
        this.props.onClickThumbnail(videoDataObj);
    }

    hideModal = (evt) => {
        this.props.close(evt);
    }

    render() {

        return (
            <Modal dialogClassName={"primaryModal modal-dialog modal-dialog-centered modal-dialog-scrollable"}
                   show={this.props.show}
                   onHide={this.hideModal} size={this.props.modelType} backdrop="static">
                <Modal.Header bsPrefix={'videos-displayer-header'} closeButton style={{border: 'none'}}>
                    <Modal.Title>{VIDEO_DISPLAYER_LIST}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="zone_links">
                        {this.props.videosData.map((videoObj, index) =>
                            <VideoDisplayerComponent
                                key={`videoDisplayer_${index.toString()}`}
                                                     videoData={videoObj}
                                                     index={index}
                                onClickThumbnail={this.onClickThumbnail}
                            />
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}