export default  class VideoRecord {

    public videoId: string;
    public videoUrl: string;
    public thumbnailUrl: string;
    public headerText?: string;
    public bodyText?: string;
    public datePublished?: string;
    public artistName?: string;
    public moreInfo?: string;
    public videoElement: string;


    constructor(videoDBRecord: any) {
        console.log("VideoRecord is created", videoDBRecord);
        this.videoId = videoDBRecord.videoId;
        this.videoUrl = process.env.PUBLIC_URL + `/videos/${videoDBRecord.videoUrl}`;
        this.thumbnailUrl = process.env.PUBLIC_URL + `/paintings/Thumbnails/${videoDBRecord.thumbnailUrl}` ;
        this.artistName = videoDBRecord.artistName || null;
        this.headerText = videoDBRecord.headerText || null;
        this.moreInfo = videoDBRecord.moreInfo || null;
        this.bodyText = videoDBRecord.bodyText || null;
        this.datePublished = videoDBRecord.datePublished || null;
        this.videoElement = videoDBRecord.videoElement;

    }

    // getVideoDataObj(videoId: string): any {
    //     console.log("VideoRecord.getVideoDataObj", this);
    //     return this;
    // }
}
