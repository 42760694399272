import {Color} from "three";
import * as THREE from "three";

export const MATERIAL_PROPS_DB = [


    {
        materialName: "FloorMat",


        aOIntensity:0.27727567508571804,
        roughness:0.5820473803687348,
        envMapIntensity:1.913607803145211,
    },

    {
        materialName: "GlassMat",
        glass: true,
        glassMatFunc: (envMap) => {
            return new THREE.MeshPhysicalMaterial({
                name: `GlassMat-MPM`,
                color: new THREE.Color(255, 255, 255),
                metalness: 0,
                roughness: 0,
                // alphaMap: texture,
                alphaTest: 0.5,
                envMap: envMap, //this.backgroundMap,
                envMapIntensity: .2,
                depthWrite: false,
                transmission: .7, // use material.transmission for glass materials
                opacity: 1, // set material.opacity to 1 when material.transmission is non-zero
                transparent: true
            });
        },
    },

    {
        materialName: "VideoButtonBaseMat",

        envMapIntensity: 4.179193713700034,

    },

    {
        materialName: "UVT1Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT2Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT3Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT4Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT5Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT6Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT7Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT8Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT9Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT10Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT11Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT12Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT13Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT14Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT15Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT16Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT17Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT18Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT19Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT20Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT21Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT22Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT23Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT24Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT25Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT26Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT27Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT28Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT29Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT30Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT31Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT32Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT33Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT34Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT35Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT36Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT37Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT38Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT39Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT40Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT41Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT42Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT43Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT44Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT45Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT46Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT47Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT48Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT49Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT50Mat",
        toneMapped: 'N',
    },
    {
        materialName: "UVT51Mat",
        toneMapped: 'N',
    },

    {
        materialName: "MEA2021_I_UVT_01",
        toneMapped: 'N',
    },

    {
        materialName: "MEA2021_I_UVT_02",
        toneMapped: 'N',
    },

    {
        materialName: "MEA2021_I_UVT_03",
        toneMapped: 'N',
    },

    {
        materialName: "MEA2021_I_UVT_04",
        toneMapped: 'N',
    },

    {
        materialName: "MEA2021_I_UVT_05",
        toneMapped: 'N',
    },

    {
        materialName: "MEA2021_I_UVT_06",
        toneMapped: 'N',
    },

    {
        materialName: "MEA2021_I_UVT_07",
        toneMapped: 'N',
    },


    {
        materialName:"WallTopPanelEmmisiveLights",
        toneMapped: 'N',
    },


    /* {
         materialName: "WallMat",
         aOIntensity: 0.6292165359859184,

     },
     {
         materialName: "CeilingLigihtPanelMat",
         aOIntensity: 0.4292165359859184,

     },*/

    //changed by Claire
    {

        materialName: "CeilingDecorationMat",
        aOIntensity: 1.0367270242569182,
        envMapIntensity: 5.09754014349163,

    },

    {
        materialName: "CeilingMat",
        aOIntensity: 0.9383327639221044,
        envMapIntensity: 6.3985309190297235,

    },
    {
        materialName: "CeilingLigihtPanelMat",
        aOIntensity: 0.4463614622480355,
        envMapIntensity: 5.02101127434233,
    },

    {
        materialName: "WallMat",
        // aOIntensity: 0.872736590365562,
        aOIntensity: 0.37,
        envMapIntensity: 3.5669627605056373,
    },


]
