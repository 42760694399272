import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
//import "bootstrap/dist/css/bootstrap.min.css";
import Image from 'react-bootstrap/Image'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {Col} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import VideoComponent from "../../util/VideoComponent";
import Carousel from "react-bootstrap/Carousel";

const HelpModal = (props) => {


    const hideModal = (evt) => {
        props.closefunc(evt);
    };

    const imageURL =  window.isMobile ? process.env.PUBLIC_URL + "/images/MobilePortrait_QuestionMarkHelpCard.png" : process.env.PUBLIC_URL + "/images/Desktop_QuestionMarkHelpCard.png";

    return (
        <div className="help-dialog">
            <Modal dialogClassName={"primaryModal modal-dialog modal-dialog-centered modal-dialog-scrollable"} show={props.showHelp}
                   onHide={hideModal} size={props.modelType} >
                <Modal.Header closeButton style={{border:'none'}}  >
                </Modal.Header>
                <Modal.Body>
                    <Carousel interval={null} indicators={false}>
                        <Carousel.Item>
                            <Container className="text-center">
                                <div className="help-divs">
                                    <h5>User Tips</h5>
                                    {/*<div className="help-troubleshooting">*/}
                                    {/*    <ul className="text-left">*/}
                                    {/*        <li>Double click on the floor or an image to go to that spot.</li>*/}
                                    {/*    </ul>*/}
                                    {/*</div>*/}
                                    {window.isMobile
                                        ? <p className="text-center"><b style={{color: '#FFC417', fontSize: "large"}}>Double tap on the floor or an image to go to that spot.</b></p>
                                        : <p className="text-center"><b style={{color: '#FFC417', fontSize: "x-large"}}>Double click on the floor or an image to go to that spot.</b></p>
                                    }


                                    <p className="text-center help-image-paragraph">
                                        <Image className="img-responsive"
                                               style={{maxWidth: "60%"}}
                                               src={imageURL }
                                               alt={"Entry Image"} fluid/>
                                    </p>
                                    {/*<div>*/}
                                    {/*    <p>*/}
                                    {/*        Click on the button that*/}
                                    {/*    </p>*/}
                                    {/*</div>*/}
                                </div>

                            </Container>
                        </Carousel.Item>

                        {window.isMobile ? null
                            :
                            <Carousel.Item>
                                <div>
                                    <h5 className="text-center">'How To' Video</h5>
                                    <video playsInline width="100%" controls>
                                        <source src={process.env.PUBLIC_URL + `/videos/Image_viewing_walkthrough.mp4`} type="video/mp4"/>
                                        <source src={process.env.PUBLIC_URL + `/videos/Image_viewing_walkthrough.mp4`} type="video/ogg"/>
                                        Your browser does not support HTML video.
                                    </video>
                                </div>
                            </Carousel.Item>
                        }

                        <Carousel.Item>
                            <Container className="text-center">
                                <div className="help-divs">
                                    {/*<h5>Troubleshooting</h5>*/}
                                    {/*<div className="help-troubleshooting">*/}
                                    <div className="help-troubleshooting-ul">
                                        <h5 className="text-center">Troubleshooting</h5>
                                        <ul className="text-justify">
                                            <li>If this experience begins to lag on your device try reducing the size of your browser window or downscaling your screen resolution.</li>
                                            <li>Please ensure only one video screen is playing at once as multiple screens playing can produce lag in the experience.</li>
                                            <li>This web experience is optimised for PC and Laptop. Recommended viewing on Google Chrome and Firefox browsers</li>
                                        </ul>
                                    </div>
                                </div>
                            </Container>
                        </Carousel.Item>
                    </Carousel>


                    {/*<Container>
                        <Row className="justify-content-sm-center">
                            <Col><p className="text-center"><Image className="img-responsive" style={{maxHeight:'500px'}}
                                src={props.modelType === 'sm' ? process.env.PUBLIC_URL + "/images/MobilePortrait_QuestionMarkHelpCard.png" : process.env.PUBLIC_URL + "/images/Desktop_QuestionMarkHelpCard.png" }
                                alt={"Entry Image"} fluid/></p></Col>
                        </Row>
                    </Container>*/}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default HelpModal;