
import {Scene, Object3D, Raycaster, Vector3, Camera, Intersection, Mesh, ShaderMaterial} from "three";
import {OutlinePass} from "three/examples/jsm/postprocessing/OutlinePass"

export type MeshCallback = (paintingId:string, node:Object3D)=> void | null;

export class MeshContainer {
    private raycaster = new Raycaster();
    private mouseVector = new Vector3();
    private meshesObj: Record<string,Object3D> = {}
    private meshes: Object3D[] = [];
    private selectedObject:Object3D | null = null;
    private prevSelectedObject:Object3D | null = null;
    constructor(private camera:Camera,private meshCallback: MeshCallback,private outlinePass:OutlinePass | null) {

        if (this.outlinePass) {
            this.outlinePass.visibleEdgeColor.set("#FF0000");
            this.outlinePass.edgeThickness = 1;
            //this.outlinePass.edgeGlow = 0;
            this.outlinePass.edgeStrength = 10;
        }
    }

    addMesh = (obj3d: Object3D ) => {
        if (obj3d.type === 'Group') {
            obj3d.children.forEach(node => {
                node.userData["painting_id"] = obj3d.userData.painting_id;
                this.meshesObj[node.id] = node;
            })
        }
        this.meshes.push(obj3d);
        this.meshesObj[obj3d.id] = obj3d;
    }

    meshSelected = (meshPaintingId: string,node:Object3D): void => {

        //use modal annotation
        if (this.meshCallback !== null) {
            if (this.outlinePass) {
                this.outlinePass.selectedObjects = [node];
            }
            this.meshCallback(meshPaintingId,node);

        }
    }

    hoverOver = (meshPaintingId: string,node:Object3D) => {

    }

    meshUnselected = () => {

    }

    onDocumentMouseMove = (event: MouseEvent,useForHovering = false):boolean => {

        let intersects:Intersection[] = [];

        intersects = this.getIntersects(event.clientX, event.clientY);
        const intersectLength = intersects.length;
        if (intersectLength > 0) {

            let res = intersects[0];

            if ((res.object.id in this.meshesObj)) {
                this.selectedObject = res.object;
                if (useForHovering) {
                    this.hoverOver(this.selectedObject.userData.painting_id, this.selectedObject);
                } else {
                    this.meshSelected(this.selectedObject.userData.painting_id, this.selectedObject);
                }
                return true;
            }
        }
        this.meshUnselected();
        return false;

    }

    getIntersects = (x: number, y:number):Intersection[] => {

        x = (x / window.innerWidth) * 2 - 1;
        y = -(y / window.innerHeight) * 2 + 1;

        this.mouseVector.set(x, y, 0.5);
        this.raycaster.setFromCamera(this.mouseVector, this.camera);

        return this.raycaster.intersectObjects(this.meshes, true);

    }


}
