import * as THREE from "three";
import {CubeCameraDB, CubeCameraRecord} from "../../../../client-data/ts/CubeCameraDB";

type CubeCameraList = {
    cubeCameraRecord: CubeCameraRecord,
    cubeCamera: THREE.CubeCamera
}


export class CubeCameraTextureGenerator {

    private _cubeCameraCount: number = 0;
    private _cubeCameraRecords: Array<CubeCameraList> = [];
    private _cameraIndex = 0;
    private _disable = false;
    private _isCameraReady = false;

    constructor(private _renderer: THREE.WebGLRenderer, private _scene: THREE.Scene) {

    }

    createCubeCamera = (position: THREE.Vector3): THREE.CubeCamera => {
        const cubeRender = new THREE.WebGLCubeRenderTarget(256, {
            format: THREE.RGBFormat,
            generateMipmaps: true,
            minFilter: THREE.LinearMipmapLinearFilter,
            encoding: THREE.sRGBEncoding // temporary -- to prevent the material's shader from recompiling every frame
        });
        const cubeCamera = new THREE.CubeCamera(1, 1000, cubeRender);
        cubeCamera.position.copy(position);

        return cubeCamera;
    }

    addCubeCameraRecords = (meshRecords: Array<THREE.Mesh>) => {
        //console.log("+++++addCubeCameraRecords begin");
        let itemExists = false;

        meshRecords.forEach(mesh => {
            //console.log("+++++addCubeCameraRecords userdata-",mesh.userData.cubeCamera_id);
            const cameraRec = CubeCameraDB.find((rec) =>
                rec.cubeCamera_id === mesh.userData.cubeCamera_id
            );
            if (cameraRec) {
                // console.log("+++++addCubeCameraRecords camrec found", cameraRec);
                let materials: Array<THREE.Material>;
                const mat = mesh.material;
                if (mat) {
                    if (Array.isArray(mat)) {
                        materials = mat;
                    } else {
                        materials = [mat];
                    }

                    cameraRec.materials = <Array<THREE.MeshStandardMaterial>>materials;
                    const cubeCamera = this.createCubeCamera(cameraRec.position);
                    this._cubeCameraRecords.push({cubeCameraRecord: cameraRec, cubeCamera: cubeCamera});
                    itemExists = true;
                }

            }
        })

        this._cubeCameraCount = this._cubeCameraRecords.length;
        this._isCameraReady = itemExists;

    }

    renderCubeCameraTextures = () => {
        if (this._disable || !this._isCameraReady) return;
        console.log("renderCubeCameraTextures")
        this._cubeCameraRecords[this._cameraIndex].cubeCamera.update(this._renderer, this._scene);
        const materials = this._cubeCameraRecords[this._cameraIndex].cubeCameraRecord.materials;
        if (materials) {
            materials.forEach(item => {
                item.envMap = this._cubeCameraRecords[this._cameraIndex].cubeCamera.renderTarget.texture;
            })
        }

        this._cameraIndex++;
        this._cameraIndex %= this._cubeCameraCount;
    }

    get disabled(): boolean {
        return this._disable;
    }

    set disabled(value: boolean) {
        this._disable = value;
    }
}
