import {Euler, Vector3, Scene, SpotLight, PointLight, DirectionalLight, AmbientLight, Color,RectAreaLight} from "three";
import {thisIsMobile} from "./GlobalConstants";


export const LIGHTPROBE_CONFIG = {
    useProbe:false, //only used if Cubemap is available
    intensity:.01,
    name:'lightprobe-01'
};
//6 sides  direct light around the object. 0 intensity will disable the light
export const SIDES_LIGHT_CONFIG = {

    generateSidesLights:false,
    XPosIntensity:2, // set to zero to be ignored
    XPosColor:new Color(0xFFFFFF),
    XNegIntensity:2,
    XNegColor:new Color(0xFFFFFF),
    ZPosIntensity:2,
    ZPosColor:new Color(0xFFFFFF),
    ZNegIntensity:2,
    ZNegColor:new Color(0xFFFFFF),
    BottomIntensity:.1,
    BottomColor:new Color(0xFFFFFF),
    TopIntensity:2.5,
    TopColor:new Color(0xFFFFFF), //94 73 20

    unifiedColor: new Color(0xFFFFFF), //if above individual color not specified then set color of light to this
}

export const LIGHTS = [

    {
        name: 'Ambient',
        light: new AmbientLight(),
        color: new Color(0xFFFFFF),
        intensity:6.5,

    },

     /* {
          name: 'RectAreaLight',
          light: new RectAreaLight(),
          color: new Color(0xffffff),
          intensity:20,
          position: new Vector3(0, 5, 50),
          lookAt: new Vector3(0, 5, 52),
          width:14,
          height:.75,

      },*/
   /* {
          name: 'PointLight',
          light: new PointLight(),
          color: new Color(0xffffff),
          intensity:30,
          position: new Vector3(0, 5, 43.7),
          // lookAt: new Vector3(0, .1, 20.44),
            decay: 2,
            distance: 12,

      },*/

    /* {
         name: 'PointLightDoor',
         light: new PointLight(),
         position: new Vector3(-9, 2.8, -7),
         color: new Color(0xffffff),
         intensity: 4.6,
         decay: .53,
         distance: 7,
     }*/
    /* {
         name: 'PointLightCenter',
         light: new PointLight(),
         position: new Vector3(-2, 3.5, 7.6),
         color: new Color(0xffffff),
         intensity: 10,
         decay: .65,
         distance: 15,
     }*/
    /*  {
          name: 'Direct-1',
          light: new DirectionalLight(),
          position: new Vector3(5.4, 10, 24.8),
          target: new Vector3(-2, 2.3, 7.6),
          color: new Color(0xffffff),
          intensity: 0.8 * Math.PI,

      },
      {
          name: 'Direct-2',
          light: new DirectionalLight(),
          position: new Vector3(5.4, 10, -28.6),
          target: new Vector3(-2, 2.3, 7.6),
          color: new Color(0xffffff),
          intensity: 0.8 * Math.PI,

      },
      {
          name: 'Direct-3',
          light: new DirectionalLight(),
          position: new Vector3(-27.76, 10, -6.7),
          target: new Vector3(-2, 2.3, 7.6),
          color: new Color(0xffffff),
          intensity: 0.8 * Math.PI,

      },
      {
          name: 'Direct-4',
          light: new DirectionalLight(),
          position: new Vector3(27.76, 10, -4),
          target: new Vector3(-2, 2.3, 7.6),
          color: new Color(0xffffff),
          intensity: 0.8 * Math.PI,

      },
      {
          name: 'PointLightCenter',
          light: new PointLight(),
          position: new Vector3(-2, 2.3, 7.6),
          color: new Color(0xffffff),
          intensity: 10,
          decay: .65,
          distance: 15,
      },*/
]
