export const environments = [
  {
    id: '',
    name: 'None',
    path: null,
    format: 'none'
  },
  {
    id: 'goegap_02k.hdr',
    name: 'goegap_02k.hdr',
    path: '/assets/environment/goegap_02k.hdr',
    format: 'hdr'
  },
  {
    id: 'kiara_5_noon_02k.hdr',
    name: 'kiara_5_noon_02k.hdr',
    path: '/assets/environment/kiara_5_noon_02k.hdr',
    format: 'hdr'
  },
  {
    id: 'kloppenheim_05_2k.hdr',
    name: 'kloppenheim_05_2k.hdr',
    path: '/assets/environment/kloppenheim_05_2k.hdr',
    format: 'hdr'
  },
  {
    id: 'sunflowers_1k.hdr',
    name: 'sunflowers_1k.hdr',
    path: '/assets/environment/sunflowers_1k.hdr',
    format: 'hdr'
  },

  {
    id: 'photo_studio_01_1k.hdr',
    name: 'photo_studio_01_1k.hdr',
    path: '/assets/environment/photo_studio_01_1k.hdr',
    format: 'hdr'
  },
  {
    id: 'kiara_4_mid-morning_01k.hdr',
    name: 'kiara_4_mid-morning_01k.hdr',
    path: '/assets/environment/kiara_4_mid-morning_01k.hdr',
    format: 'hdr'
  },
  {
    id: 'kiara_4_mid-morning_04k.hdr',
    name: 'kiara_4_mid-morning_04k.hdr',
    path: '/assets/environment/kiara_4_mid-morning_04k.hdr',
    format: 'hdr'
  },

  {
    id: 'rainbow_1K_X1000_02.hdr',
    name: 'rainbow_1K_X1000_02.hdr',
    path: '/assets/environment/wave_grey_white_1K_X1000_08.hdr',
    format: 'hdr'
  },


  {
    id: 'canary_wharf',
    name: 'canary_wharf',
    path: '/assets/environment/canary_wharf/',
    format: 'cubemap',
    images:[ 'px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png' ],
  },
  {
    id: 'photoStudio',
    name: 'photoStudio',
    path: '/assets/environment/photoStudio/',
    format: 'cubemap',
    images:[ 'px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png' ],
  },
  {
    id: 'canary_wharf_4k.hdr',
    name: 'Houser Wirth (HDR)',
    path: '/assets/environment/canary_wharf_4k.hdr',
    format: 'hdr'
  },
  {
    id: 'spiaggia_di_mondello_4k',
    name: 'spiaggia_di_mondello_4k',
    path: '/assets/environment/spiaggia_di_mondello_4k/',
    format: 'cubemap',
    images:[ 'px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png' ],
  },

  {
    id: 'greenwich_park_03_4k.hdr',
    name: 'greenwich_park_03_4k.hdr',
    path: '/assets/environment/greenwich_park_03_4k.hdr',
    format: 'hdr'
  },

  {
    id: 'greenwich_park_4k.hdr',
    name: 'greenwich_park_4k.hdr',
    path: '/assets/environment/greenwich_park_4k.hdr',
    format: 'hdr'
  },
  {
    id: 'autumn_park_1k.hdr',
    name: 'autumn_park_1k.hdr',
    path: '/assets/environment/autumn_park_1k.hdr',
    format: 'hdr'
  },



  /*{
    id: 'surgery_4k.hdr',
    name: 'surgery_4k.hdr',
    path: '/assets/environment/surgery_4k.hdr',
    format: 'hdr'
  },*/
 {
    id: 'old_depot_4k.hdr',
    name: 'old_depot_4k.hdr',
    path: '/assets/environment/old_depot_4k.hdr',
    format: 'hdr'
  },

  {
    id: 'blender_white_emmisive.hdr',
    name: 'blender_white_emmisive.hdr',
    path: '/assets/environment/blender_white_emmisive.hdr',
    format: 'hdr'
  },
  {
    id: 'palermo_park_4k.hdr',
    name: 'palermo_park_4k.hdr',
    path: '/assets/environment/palermo_park_4k.hdr',
    format: 'hdr'
  },

  {
    id: 'canary_wharf_1k.hdr',
    name: 'canary_wharf_1k.hdr',
    path: '/assets/environment/canary_wharf_1k.hdr',
    format: 'hdr'
  },

  {
    id: 'cannon_2k.exr',
    name: 'cannon_2k.exr',
    // path: '/assets/environment/cannon_2k.exr',
    path: '/assets/environment/cannon_2k.exr',
    format: 'exr'
  },

  {
    id: 'cannon_1k_rotated01.hdr',
    name: 'cannon_1k_rotated01.hdr',
    // path: '/assets/environment/cannon_2k.exr',
    path: '/assets/environment/cannon_1k_rotated01.hdr',
    format: 'hdr'
  },


  {
    id: 'west_rendered.hdr',
    name: 'west_rendered.hdr',
    // path: '/assets/environment/cannon_2k.exr',
    path: '/assets/environment/west_rendered.hdr',
    format: 'hdr'
  },

  {
    id: 'JohnHughes_West',
    name: 'JohnHughes-Wes',
    path: '/assets/environment/JohnHughes_West/',
    format: 'cubemap',
    images:[ 'px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png' ],
  },





];


