import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed';


class ChatModal extends Component {


    render() {
        // console.log("[ChatModal]", this.props.annotationData.paintingId);
        return (
            <Modal
                show={this.props.showChat}
                onHide={this.props.chatCloseBtnHandler}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {this.props.annotationData.chatTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="chat-modal-body">
                    <iframe title="chatIframe" src={this.props.annotationData.chatSrc}
                            height="100%" width="100%" style={{minHeight: "560px"}}></iframe>
                </Modal.Body>
            </Modal>
        )
    }
}

export default ChatModal;