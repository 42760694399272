import {Euler, Vector3, Scene} from "three";

const FLOOR_NUMBER = {
    BOTTOM_FLOOR: 1,
    TOP_FLOOR: 2,
};
// Here we split by Artists Names
//ChatRoom01
//ScreenStageLeft
const CameraLocationsDB = [
    {
        cameraLocationId: 'WELWT01',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Welcome Panel',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'INTWT01',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Pro & Open Competition',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'ARCWT01',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Architecture',
        // cameraPosition: new Vector3(6.459, -1, .408),
        // cameraRotation: new Euler(0, 1.249, 0, 'XYZ'),
        // offset3D: new Vector3(0,1.25,0), // type Vector3 - offsets the camera by adding Vector3 to the position -  applies only on locations set in Blender. Default is Vector3(0,0,0) see also clientOption.CAMERA_LOCATION_OFFSET
        // cameraLocationOffset: 3, //type float overrides  the global clientOption.CAMERA_LOCATION_OFFSET
        // cameraLocationLevel2Id: ['BSCH01']
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'CREWT01',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Creative',
        // cameraPosition: new Vector3(6.459, -1, .408),
        // cameraRotation: new Euler(0, 1.249, 0, 'XYZ'),
        // offset3D: new Vector3(0,1.25,0), // type Vector3 - offsets the camera by adding Vector3 to the position -  applies only on locations set in Blender. Default is Vector3(0,0,0) see also clientOption.CAMERA_LOCATION_OFFSET
        // cameraLocationOffset: 3, //type float overrides  the global clientOption.CAMERA_LOCATION_OFFSET
        // cameraLocationLevel2Id: ['BSCH01']
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'PORWT01',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Portfolio',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'DOCWT01',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Documentary',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'ENVWT01',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Environment',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'LANWT01',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Landscape',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'NWWWT01',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Natural World & Wildlife',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'PORTWT01',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Portraiture',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'SPOWT01',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Sport',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'STIWT01',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Still Life',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'LATWT01',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: '2021 Latin America Professional Award',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'LATWT02',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: '2021 Latin America Professional Award (Continue...)',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'SGWT01',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: '2021 Sony Grant Professional/Student',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'STUWT01',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Students',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'ALPWT01',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: '2021 Sony Alpha Female Award',
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'OCPWT01',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Outstanding Contribution to Photography',
        cameraLocationOffset: 1.5,
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'NATWT01',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Middle East & Africa',
        cameraLocationOffset: 1.5,
        ObjectId:null,
        displayMesh: 'Y',
    },
    {
        cameraLocationId: 'EXIWT01',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Exit',
        ObjectId:null,
        displayMesh: 'Y',
    },
];

export {CameraLocationsDB, FLOOR_NUMBER};