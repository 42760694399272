import * as THREE from "three";
import {NavMeshContainer} from "../../navigation/ts/NavMeshContainer";
import {NavMeshPlayer} from "../../navigation/ts/NavMeshPlayer";
import {getNavigationTargetPosition, MeshTransform} from "../../navigation/ts/NavMeshUtils";
import * as TWEEN from "@tweenjs/tween.js";
import {Vector3} from "three";

const EMPTY_EULER = new THREE.Euler();
const EMPTY_QUATERNION = new THREE.Quaternion();
const VEC_UP = new Vector3(0,1,0);
const MESH_TARGET_TWEEN_DELAY = 500; //delay for mesh target
const POSITION_TARGET_TWEEN_DELAY = 250; //delay for Vector3  position

export class CameraNavMeshHandler {


    private navmeshPlayer: NavMeshPlayer;
    private targetNode?:THREE.Object3D;
    private cameraYOffset:number = 1.5;
    private readonly raycaster = new THREE.Raycaster();
    private lookAt:boolean = false;
    private _locked = false; // used to prevent concurrency
    private navigationDelay = MESH_TARGET_TWEEN_DELAY;
    // private
    constructor(private camera: THREE.PerspectiveCamera,private navMeshContainer:NavMeshContainer) {
        this.navmeshPlayer = this.navMeshContainer.createPlayer(this.camera,this.onInitPlayerCallBack,this.onStartPlayerCallBack,this.onUpdatePlayerCallBack,this.onCompletePlayerCallBack);
    }

    navigateToTargetPos =(node:THREE.Object3D, jumpToTarget = false)=> {

        //  used to prevent concurrency
        if (this._locked) return;


        this.lookAt = true;
        const target = getNavigationTargetPosition(node);
        this.targetNode = node;
        this.navigationDelay = MESH_TARGET_TWEEN_DELAY;


        if (jumpToTarget) {
            this._locked = true;
            this.jumpToTargetPosition(target);
        }
        else {

            if (this.navmeshPlayer.navigateToTargetPos(target, true, false, this.navigationDelay)) {
                this._locked = true;
            }
        }

    }

    navigateToNanMeshPoint = (pos:THREE.Vector3) => {

        //  used to prevent concurrency
        if (this._locked) return;


        this.lookAt = true;
        const target:MeshTransform = {
            rotationEuler:EMPTY_EULER,
            position:pos,
            rotationQuaternion:EMPTY_QUATERNION,
        };
        this.navigationDelay = POSITION_TARGET_TWEEN_DELAY;
        if (this.navmeshPlayer.navigateToTargetPos(target,true,true,this.navigationDelay)) {
            this._locked = true;
        }

    }

    onInitPlayerCallBack = (pos: THREE.Vector3,index:number,pathLength:number,pathSegmentEndPos: THREE.Vector3, meshTransform?:MeshTransform) => {
        this.cameraYOffset = this.camera.position.y - pos.y;

        let targetPos: THREE.Vector3;


        if (meshTransform && meshTransform.node && pathLength === 1) {

            targetPos = new THREE.Vector3();
            meshTransform.node.getWorldPosition(targetPos);
        } else {
            targetPos = pathSegmentEndPos.clone();
            targetPos.y = this.camera.position.y;
        }

        this.rotateCameraToTarget(targetPos, this.navigationDelay);


    }

    onUpdatePlayerCallBack = (pos:THREE.Vector3,index:number,pathLength:number,pathSegmentEndPos: THREE.Vector3, meshTransform?:MeshTransform) => {

        const offsetPos = pos.clone();
        offsetPos.y += this.cameraYOffset;
        this.camera.position.copy(offsetPos);

        console.log("********onUpdatePlayerCallBack", index, pathLength,pos,offsetPos,pathSegmentEndPos,meshTransform);

        let targetPos: THREE.Vector3;

        if ((pathLength === 1) || index === (pathLength - 1) ) {

            if (meshTransform && meshTransform.node) {

                targetPos = new THREE.Vector3();
                meshTransform.node.getWorldPosition(targetPos);
                targetPos.y = offsetPos.y;
                this.rotateCameraToTarget(targetPos );
            }

        }
        else {

            targetPos = pathSegmentEndPos.clone();
            targetPos.y = offsetPos.y;
            //this.rotateCameraToTarget(targetPos);

        }

    }

    onStartPlayerCallBack = (pos:THREE.Vector3,index:number,pathLength:number,pathSegmentEndPos: THREE.Vector3, meshTransform?:MeshTransform) => {

    }

    onCompletePlayerCallBack = (pos:MeshTransform) => {
        if (!pos.node) {
            this._locked = false;
            return;
        }
        const wp = new THREE.Vector3()
        pos.node.getWorldPosition(wp)
        const nodePos = wp.clone();
        nodePos.y = this.camera.position.y;
        this.rotateCameraToTarget(nodePos,500, () => {
            this._locked = false;
        })

    }

    update = () => {
        this.navmeshPlayer.update();
    }

    onMouseDbClick = (event: MouseEvent): THREE.Vector3 | null => {
        const mouse = new THREE.Vector2();

        mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
        mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

        this.camera.updateMatrixWorld();

        this.raycaster.setFromCamera( mouse, this.camera );

        const intersects = this.raycaster.intersectObject( this.navmeshPlayer.navMeshContainer.navMesh );

        if ( !intersects.length ) return null;

        return intersects[0].point.clone();

    }

    moveForward = (distance:number) => {
        //  used to prevent concurrency
        if (this._locked) return;
        this._locked = true;

        if (distance === 0) {
            this._locked = false;
            return;
        }

        let vec = new Vector3();

        vec.setFromMatrixColumn( this.camera.matrix, 0 );

        vec.crossVectors( VEC_UP, vec );


        const target = this.camera.position.clone();
        target.addScaledVector(vec,distance);
        this.moveToTarget(target);

    }

    moveSide = (distance:number) => {
        //  used to prevent concurrency
        if (this._locked) return;
        this._locked = true;

        if (distance === 0) {
            this._locked = false;
            return;
        }

        let vec = new Vector3();

        vec.setFromMatrixColumn( this.camera.matrix, 0 );

        const target = this.camera.position.clone();
        target.addScaledVector(vec,distance);
        this.moveToTarget(target);

    }

    private moveToTarget = (target:THREE.Vector3) => {

        this.camera.updateMatrixWorld(true);
        const intersectTarget = this.navmeshPlayer.rayTraceNaveMesh(target);
        if (intersectTarget.length) {
            const cameraPos = this.camera.position.clone();
            const intersectPlayer = this.navmeshPlayer.rayTraceNaveMesh(cameraPos);
            if (intersectPlayer.length) {
                target.y = intersectTarget[0].point.y;
                const playerOffsetY = cameraPos.y - intersectPlayer[0].point.y;
                const newPos = this.navmeshPlayer.clampStep(intersectPlayer[0].point, target);
                if (newPos) {
                    newPos.y += playerOffsetY;
                    this.camera.position.copy(newPos);

                } else {
                    console.log("CameraNavMeshHandler.moveToTarget clampStem is null");
                }
            }
            else {
                console.log("moveForward Player no intersect");
            }
        }
        else {
            console.log("moveForward Target no intersect");
        }

        this._locked = false;
    }

    get locked(): boolean {
        return this._locked;
    }

    rotateCameraToTarget = (pos: THREE.Vector3, duration = 0, onCompleteCB?: (() => void)  ) => {

        const matrix4 = this.camera.matrix.clone();
        matrix4.lookAt( this.camera.position, pos, VEC_UP );
        const quaternion = new THREE.Quaternion().setFromRotationMatrix(matrix4);
        const cameraQuaternion = this.camera.quaternion.clone();

        if (duration) {

            let time = {t: 0};
            new TWEEN.Tween(time)
                .to({t: 1}, duration)
                .easing(TWEEN.Easing.Quadratic.InOut)
                .onUpdate(() => {
                    THREE.Quaternion.slerp(cameraQuaternion, quaternion, this.camera.quaternion, time.t);
                })
                .onComplete(() => {
                    if (onCompleteCB) {
                        onCompleteCB();
                    }
                })
                .start()
        }
        else {
            this.camera.quaternion.copy(quaternion);
            if (onCompleteCB) {
                onCompleteCB();
            }
        }

        //this.camera.matrix.lookAt( this.camera.position, pos, VEC_UP );
        //this.camera.quaternion.setFromRotationMatrix( this.camera.matrix );
    }

    jumpToTargetPosition = (meshTransform:MeshTransform )=> {

        const target = meshTransform.position.clone();

        this.camera.updateMatrixWorld(true);
        const intersectTarget = this.navmeshPlayer.rayTraceNaveMesh(target);
        if (intersectTarget.length) {
            const cameraPos = this.camera.position.clone();
            const intersectPlayer = this.navmeshPlayer.rayTraceNaveMesh(cameraPos);
            if (intersectPlayer.length) {
                target.y = intersectTarget[0].point.y;
                const playerOffsetY = cameraPos.y - intersectPlayer[0].point.y;
                const newPos = this.navmeshPlayer.clampStep(intersectPlayer[0].point, target);
                if (newPos) {
                    newPos.y += playerOffsetY;
                    this.camera.position.copy(newPos);
                    const lookAtPos = meshTransform.node!.position.clone();
                    lookAtPos.y  = newPos.y;
                    this.rotateCameraToTarget(lookAtPos)

                } else {
                    console.log("CameraNavMeshHandler.jumpToTargetPosition clampStem is null");
                }
            }
            else {
                console.log("CameraNavMeshHandler.jumpToTargetPosition Player no intersect");
            }
        }
        else {
            console.log("CameraNavMeshHandler.jumpToTargetPosition Target no intersect");
        }

        this._locked = false;

    }

}
