import React from "react";
import VideoComponent from "../util/VideoComponent";

const VideosComponent = (props) => {
    return (
        <div>

            {/*/!*Audio*!/*/}
            <VideoComponent id="ARCA01" src={process.env.PUBLIC_URL + `/audio/ARCA01.wav`} audio="Y"/>
            <VideoComponent id="ARCA02" src={process.env.PUBLIC_URL + `/audio/ARCA02.wav`} audio="Y"/>
            <VideoComponent id="ARCA03" src={process.env.PUBLIC_URL + `/audio/ARCA03.wav`} audio="Y"/>
            <VideoComponent id="CREA01" src={process.env.PUBLIC_URL + `/audio/CREA01.wav`} audio="Y"/>
            <VideoComponent id="CREA02" src={process.env.PUBLIC_URL + `/audio/CREA02.wav`} audio="Y"/>
            <VideoComponent id="CREA03" src={process.env.PUBLIC_URL + `/audio/CREA03.wav`} audio="Y"/>
            <VideoComponent id="PORA01" src={process.env.PUBLIC_URL + `/audio/PORA01.wav`} audio="Y"/>
            <VideoComponent id="PORA02" src={process.env.PUBLIC_URL + `/audio/PORA02.wav`} audio="Y"/>
            <VideoComponent id="PORA03" src={process.env.PUBLIC_URL + `/audio/PORA03.wav`} audio="Y"/>
            <VideoComponent id="DOCA01" src={process.env.PUBLIC_URL + `/audio/DOCA01.wav`} audio="Y"/>
            <VideoComponent id="DOCA02" src={process.env.PUBLIC_URL + `/audio/DOCA02.wav`} audio="Y"/>
            <VideoComponent id="DOCA03" src={process.env.PUBLIC_URL + `/audio/DOCA03.wav`} audio="Y"/>
            <VideoComponent id="ENVA01" src={process.env.PUBLIC_URL + `/audio/ENVA01.wav`} audio="Y"/>
            <VideoComponent id="ENVA02" src={process.env.PUBLIC_URL + `/audio/ENVA02.wav`} audio="Y"/>
            <VideoComponent id="ENVA03" src={process.env.PUBLIC_URL + `/audio/ENVA03.wav`} audio="Y"/>
            <VideoComponent id="LANA01" src={process.env.PUBLIC_URL + `/audio/LANA01.wav`} audio="Y"/>
            <VideoComponent id="LANA02" src={process.env.PUBLIC_URL + `/audio/LANA02.wav`} audio="Y"/>
            <VideoComponent id="LANA03" src={process.env.PUBLIC_URL + `/audio/LANA03.wav`} audio="Y"/>
            <VideoComponent id="NWWA01" src={process.env.PUBLIC_URL + `/audio/NWWA01.wav`} audio="Y"/>
            <VideoComponent id="NWWA02" src={process.env.PUBLIC_URL + `/audio/NWWA02.wav`} audio="Y"/>
            <VideoComponent id="NWWA03" src={process.env.PUBLIC_URL + `/audio/NWWA03.wav`} audio="Y"/>
            <VideoComponent id="POTA01" src={process.env.PUBLIC_URL + `/audio/POTA01.wav`} audio="Y"/>
            <VideoComponent id="POTA02" src={process.env.PUBLIC_URL + `/audio/POTA02.wav`} audio="Y"/>
            <VideoComponent id="POTA03" src={process.env.PUBLIC_URL + `/audio/POTA03.wav`} audio="Y"/>
            <VideoComponent id="SPOA01" src={process.env.PUBLIC_URL + `/audio/SPOA01.wav`} audio="Y"/>
            <VideoComponent id="SPOA02" src={process.env.PUBLIC_URL + `/audio/SPOA02.wav`} audio="Y"/>
            <VideoComponent id="SPOA03" src={process.env.PUBLIC_URL + `/audio/SPOA03.wav`} audio="Y"/>
            <VideoComponent id="STIA01" src={process.env.PUBLIC_URL + `/audio/STIA01.wav`} audio="Y"/>
            <VideoComponent id="STIA02" src={process.env.PUBLIC_URL + `/audio/STIA02.wav`} audio="Y"/>
            <VideoComponent id="STIA03" src={process.env.PUBLIC_URL + `/audio/STIA03.wav`} audio="Y"/>
        </div>
    )
}

export default VideosComponent;