import * as THREE from "three";
import {MeshButton } from "./MeshButton";
import {VideoMeshButton} from "./VideoMeshButton";
import {MeshButtonCallback} from "./ButtonsTypes";
import {AnimatedMeshButton} from "./AnimatedMeshButton";
import {MeshButtonBase} from "./MeshButtonBase";
import {NCDissertationButton} from "../../../client-data/ts/NCDissertationButton";

export class MeshButtonContainer {

    private raycaster = new THREE.Raycaster();
    private mouseVector = new THREE.Vector3();
    private meshesObj: Record<string, MeshButtonBase> = {};
    private selectedButtonId: string | null = null;

    constructor(private scene: THREE.Scene ,private camera: THREE.Camera, private buttons: Array<THREE.Mesh>, onClickCallback: MeshButtonCallback,
                onHoverOverCallback: MeshButtonCallback,
                onHoverOutCallback: MeshButtonCallback) {

        buttons.forEach(btn => {
            // this.meshesObj[btn.userData.button_id] = new MeshButton(btn, btn.userData.button_id, onClickCallback, onHoverOverCallback, onHoverOutCallback );
            //this.meshesObj[btn.userData.button_id] = new AnimatedMeshButton(this.scene, btn, btn.userData.button_id, onClickCallback, onHoverOverCallback, onHoverOutCallback );
            this.meshesObj[btn.userData.button_id] = new NCDissertationButton(btn, btn.userData.button_id, onClickCallback, onHoverOverCallback, onHoverOutCallback );
           // this.meshesObj[btn.userData.button_id].disabled = false;

        })
    }

    getMeshButton = (buttonId: string):MeshButtonBase | null => {
        const btnRec = this.meshesObj[buttonId];
        if (btnRec) {
            return btnRec;
        }
        return null;

    }


    buttonClicked = (buttonId: string): void => {

        const btnRec = this.getMeshButton(buttonId)

        if (btnRec) {
            btnRec.buttonClicked();
        }
    }

    hoverOver = (buttonId: string): void => {

        const btnRec = this.getMeshButton(buttonId)

        if (btnRec) {
            btnRec.hoverOver();
        }
    }

    hoverOut = (buttonId: string): void => {

        const btnRec = this.getMeshButton(buttonId)

        if (btnRec) {
            btnRec.hoverOut();
        }
    }

    onDocumentMouseMove = (event: MouseEvent): boolean => {

        let intersects: THREE.Intersection[] = [];

        intersects = this.getIntersects(event.clientX, event.clientY);
        const intersectLength = intersects.length;
        if (intersectLength > 0) {


            let res = intersects[0];
            const buttonId = res.object.userData.button_id;
            if (buttonId && (buttonId in this.meshesObj)) {
                if (this.selectedButtonId) {
                    if (this.selectedButtonId !== buttonId) {
                        this.hoverOut(this.selectedButtonId);
                        this.hoverOver(buttonId);
                        this.selectedButtonId = buttonId;
                    }

                } else {
                    this.hoverOver(buttonId);
                    this.selectedButtonId = buttonId;
                }

                return true;
            } else {

                if (this.selectedButtonId) {
                    this.hoverOut(this.selectedButtonId);
                    this.selectedButtonId = null;
                }
                return false;
            }
        }
        if (this.selectedButtonId) {
            this.hoverOut(this.selectedButtonId);
            this.selectedButtonId = null;
        }
        return false;

    }

    onDocumentMouseDown = (event: MouseEvent): boolean => {

        let intersects: THREE.Intersection[] = [];

        intersects = this.getIntersects(event.clientX, event.clientY);
        const intersectLength = intersects.length;
        if (intersectLength > 0) {


            let res = intersects[0];
            const buttonId = res.object.userData.button_id;
            if (buttonId && (buttonId in this.meshesObj)) {
                this.buttonClicked(buttonId);
                return true;
            }
        }

        return false;

    }

    getIntersects = (x: number, y: number): THREE.Intersection[] => {

        x = (x / window.innerWidth) * 2 - 1;
        y = -(y / window.innerHeight) * 2 + 1;

        this.mouseVector.set(x, y, 0.5);
        this.raycaster.setFromCamera(this.mouseVector, this.camera);

        return this.raycaster.intersectObjects(this.buttons, true);

    }
}
