import {MeshButtonBase} from "../../components/annotations/ts/MeshButtonBase";
import * as THREE from "three";
import {HoverState, MeshButtonCallback} from "../../components/annotations/ts/ButtonsTypes";

export class NCDissertationButton extends MeshButtonBase {
    constructor(button: THREE.Mesh,
                buttonId: string,
                onClickCallback?: MeshButtonCallback,
                onHoverOverCallback?: MeshButtonCallback,
                onHoverOutCallback?: MeshButtonCallback) {

        super(button, buttonId, onClickCallback, onHoverOverCallback, onHoverOutCallback)
    }


    hoverOver = () => {

        if (this.disabled) return;

        if (this.hoverState === HoverState.HOVER_OVER) {
            return;
        }

        document.body.style.cursor = "pointer";

        if (this.onHoverOverCallback) {
            this.onHoverOverCallback(this.buttonId)
        }
       // this.setMeshButtonColor(this.getDefaultHoverOverColour());
        this.hoverState = HoverState.HOVER_OVER;
    }

    hoverOut = () => {

        if (this.disabled) return;

        if (this.hoverState === HoverState.HOVER_OUT) {
            return;
        }
      //  this.setMeshButtonColor(this.buttonOriginalColor);
        document.body.style.cursor = "default";

        if (this.onHoverOutCallback) {
            this.onHoverOutCallback(this.buttonId)
        }
        this.hoverState = HoverState.HOVER_OUT;
    }
}
