import {FLOOR_NUMBER, CameraLocationsDB} from "../../client-data/CameraLocationsDB";
import {Vector3} from "three";
import {CAMERA_HEIGHT_CONSTRAINT, clientOptions} from "../../client-data/clientOptions";
const ZEROVECTOR3 = new Vector3(0,0,0);
class CameraLocationsDataController {

    static getCameraLocationsData() {
        let records = null;
         records = CameraLocationsDB.map( (record) => {
            return (
                CameraLocationsDataController.populateCameraLocationRecord(record)
            )
        });
         return records;
    }

    static getCameraLocationById(id) {
       /* const records = CameraLocationsDB.filter((rec) => rec.cameraLocationId === id)
        if (records && records.length > 0) {
            return CameraLocationsDataController.populateCameraLocationRecord(records[0]);
        }
        return null;*/

        const index = CameraLocationsDB.findIndex((item) => {
            return item.cameraLocationId === id;
        });

        if (index !== -1 ) {
            return  CameraLocationsDB[index];
        }

        return null;
    }

    static populateCameraLocationRecord(record) {

        let cameraLocationLevel2IdArray = null;

        if(record) {

            if(record.cameraLocationLevel2Id) {
                cameraLocationLevel2IdArray = [];
                record.cameraLocationLevel2Id.map(lvl2CameraLocationId => {
                    // console.log("[]")
                    cameraLocationLevel2IdArray.push(CameraLocationsDataController.getCameraLocationById(lvl2CameraLocationId));
                })
            }

            const  cameraPosition = record.cameraPosition;
            if (CAMERA_HEIGHT_CONSTRAINT) {
                if (cameraPosition) {
                    cameraPosition.y = clientOptions.initCameraOrient.position.y;
                }
            }
            return (
                {
                    cameraLocationId: record.cameraLocationId,
                    floorNumber: record.floorNumber || FLOOR_NUMBER.BOTTOM_FLOOR,
                    locationName: record.locationName,
                    cameraPosition: record.cameraPosition,
                    cameraRotation:record.cameraRotation,
                    displayMesh:record.displayMesh || 'Y',
                    offset3D: record.offset3D || ZEROVECTOR3,
                    cameraLocationOffset: record.cameraLocationOffset || 0.0,
                    cameraLocationLevel2: cameraLocationLevel2IdArray,
                    cameraLocationIdOfChild: record.cameraLocationIdOfChild || 'N',
                    ObjectId:record.ObjectId,
                }

            )

        }
        return null;
    }

    static updateCameraLocationRecord(record) {

       let rec = CameraLocationsDataController.getCameraLocationById(record.cameraLocationId);
       if (rec) {
           let {cameraPosition, cameraRotation,ObjectId} = rec;
           if (!cameraPosition) {
               rec.cameraPosition = record.cameraPosition
           }
           if (!cameraRotation) {
               rec.cameraRotation = record.cameraRotation
           }
           rec.ObjectId = record.ObjectId;
           return CameraLocationsDataController.populateCameraLocationRecord(rec);

       }
       return null;
    }

    static populateCameraLocationRecordById(id) {

        return CameraLocationsDataController.populateCameraLocationRecord(CameraLocationsDataController.getCameraLocationById(id));
    }


}

export default CameraLocationsDataController;
