import {COLLIDER_STATUS, ColliderX} from "./ColliderX";
import * as THREE from "three";

export type ColliderXCallback = ((collider: ColliderX) => void);

export class ColliderXContainer {

    private cameraCollider: ColliderX;

    constructor(private camera: THREE.Camera,
                private cameraBoxSize: THREE.Vector3,
                private colliders: Array<ColliderX>,
                private colliderEnter: ColliderXCallback,
                private colliderStay: ColliderXCallback,
                private colliderExit: ColliderXCallback,
                private showWireframe = false) {

        const cameraObject3d = new THREE.Object3D();
        cameraObject3d.userData.colliderX_id = "CameraColliderX";
        cameraObject3d.scale.copy(new THREE.Vector3(1,1,1));
        cameraObject3d.position.copy(camera.position);
        cameraObject3d.parent = camera;
        this.cameraCollider = new ColliderX(cameraObject3d, showWireframe);
    }

    public notifyContacts = () => {
        this.colliders.forEach((collider) => {
            this.cameraCollider.updatedBox3.setFromCenterAndSize(this.camera.position, this.cameraBoxSize);
            const doCollide = this.cameraCollider.collideWith(collider);
            if (doCollide) {
                if (collider.colliderStatus == COLLIDER_STATUS.intersected) {
                    //already existed
                    this.colliderStay(collider);

                }
                else {
                    collider.colliderStatus = COLLIDER_STATUS.intersected;
                    this.colliderEnter(collider)
                }
            }
            else {
                if (collider.colliderStatus == COLLIDER_STATUS.intersected) {
                    //already existed, so we are exiting
                    collider.colliderStatus = COLLIDER_STATUS.not_intersected
                    this.colliderExit(collider);

                }
            }
        });
    }
}
