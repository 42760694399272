import React, {Component} from 'react';
import VideoComponent from "../util/VideoComponent";
import * as Hls from "hls.js";

const  testVideoStreamURL =  `https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8`
const BSHF_AUDITORIUM_B_STREAM = `https://cdn3.wowza.com/1/ODYxdVJwUjgvNTBQ/ZDRuQllB/hls/live/playlist.m3u8`;
const BSHF_AUDITORIUM_A_STREAM = `https://cdn3.wowza.com/1/bEcrV2xWTWV0dDc0/WE1uNmdL/hls/live/playlist.m3u8`;
const BSHF_DISCUSSION_ROOM_A_STREAM = `https://cdn3.wowza.com/1/Y1NRVWgrNlhscDY4/WjlLeDVX/hls/live/playlist.m3u8`;
const BSHF_DISCUSSION_ROOM_B_STREAM = `https://cdn3.wowza.com/1/YU9uT0UrOUdTMmg5/T0puMkp6/hls/live/playlist.m3u8`;

class BSStreamPage extends Component {

    constructor(props) {
        super(props);
        this.viseoStreamId = this.props.match.params.id
        // console.log("[BSStreamPage]", this.videoStreamSrc);
        this.mount = React.createRef();
        this.videoStreamSrc = null;


        switch (String(this.viseoStreamId )) {

            case 'AA':
                this.videoStreamSrc = BSHF_AUDITORIUM_A_STREAM;
                break;
            case 'AB':
                this.videoStreamSrc = BSHF_AUDITORIUM_B_STREAM;
                break;
            case 'DA':
                this.videoStreamSrc = BSHF_DISCUSSION_ROOM_A_STREAM;
                break;
            case 'DB':
                this.videoStreamSrc = BSHF_DISCUSSION_ROOM_B_STREAM;
                break;
            case 'TEST' :
                this.videoStreamSrc = testVideoStreamURL;
                break;
        }
    }

    componentDidMount() {

        console.log("BSStreamPage HLS pre-init");
        if (Hls.isSupported()) {
            console.log("BSStreamPage HLS init",this.props.match.params.id);
            const hls = new Hls();
            if ( this.videoStreamSrc) {
                console.log("BSStreamPage video src=",this.videoStreamSrc)
                hls.loadSource(this.videoStreamSrc);
                hls.attachMedia(this.mount.current);
                const self = this;
                hls.on(Hls.Events.MANIFEST_PARSED, function () {
                    console.log("BSStreamPage Hls.Events.MANIFEST_PARSED:", this.props.match.params.id);

                });
            }
        }
        else {
            console.log("BSStreamPage HLS is not supported");
        }
    }

    render() {
        return (
            <div id="BSStreamPage-id">
                <video  ref={this.mount} id="BSStreamPageVideo" controls>
                    <source   type="video/mp4"/>
                    <source   type="video/ogg"/>
                    Your browser does not support the video tag.
                </video>
            </div>

        )
    }
}



export default BSStreamPage;
