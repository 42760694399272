import * as THREE from "three";

export type CubeCameraRecord = {
    position: THREE.Vector3,
    cubeCamera_id: string,
   // mesh:THREE.Mesh | null,
    materials: Array<THREE.MeshStandardMaterial> | null,
}

export const CubeCameraDB: Array<CubeCameraRecord> = [
   /* {
        position: new THREE.Vector3(0, .3, -6),
        cubeCamera_id: 'Floor',
        //mesh:null,
        materials: null,

    },*/


]
